@font-face {
  font-family: "acid-grotesk";
  src: url("/public/assets/fonts/FFF-AcidGrotesk-Light-TRIAL.otf") format("opentype");
  font-display: swap;
  text-rendering: auto;
  text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "acid-groteskregular";
  src: url("/public/assets/fonts/FFF-AcidGrotesk-Regular-TRIAL.otf") format("opentype");
  font-display: swap;
  text-rendering: auto;
  text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "acid-grotesknormal";
  src: url("/public/assets/fonts/FFF-AcidGrotesk-Normal-TRIAL.otf") format("opentype");
  font-display: swap;
  text-rendering: auto;
  text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  width: 100%;
}

/* ----------General Properties--------------- */
.item-end {
  display: flex;
  justify-content: end;
  align-items: end;
}

.nounderline {
  text-decoration: none;
}

.item-center {
  display: flex;
  align-items: center;
}

.main-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.neon {
  color: #ED4723;
  font-weight: bold;
}

.orange {
  color: #ED4723;
}
.grey{
  color: rgb(161, 159, 159);
}
.paddingright-20 {
  padding-right: 20px;
}

.paddingleft-20 {
  padding-left: 20px;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.mt-100 {
  margin-top: 100px;
}
.w-100{
  width: 100%;
}
.mb-0 {
  margin-bottom: 0;
}

.mb-100 {
  margin-bottom: 100px;
}

.ptop100 {
  padding-top: 100px;
}

.pbottom50 {
  padding-bottom: 50px;
}

.h-80 {
  height: 80vh;
}

.ptop50 {
  padding-top: 50px;
}

.pt-10{
  padding-top: 10px;
}
.ptop20 {
  padding-top: 20px;
}

.pbottom20 {
  padding-bottom: 20px;
}

.pb100 {
  padding-bottom: 100px;
}

.pb200 {
  padding-bottom: 200px;
}
.mt-50 {
  margin-top: 50px;
}

.mb-0 {
  margin-bottom: 0;
}
.mb20{
  margin-bottom: 20px;
}
.mb50{
  margin-bottom: 50px;
}
.mt-20{
  margin-top: 20px;
}
.white {
  color: white;
}

.grey {
  color: #8080809c;
}

.black {
  color: black;
}

.whatsappbtn {
  margin-left: 20px;
}

.whatsappbtn svg {
  font-size: 25px;
  margin-left: 20px;
  color: #ED4723;
}

.borderbottom {
  border-bottom: 2px solid;
}

.relative {
  position: relative;
}

.grotesklight {
  font-family: "acid-grotesk";
}

.groteskregular {
  font-family: "acid-groteskregular";
}

.animateheading {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
.back-video {
  width: 100%;
  height: auto;
}
.font-bold{
  font-weight: bold;
}
/* ------------End General Properties------------- */
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ED4723;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: white;
}

/* ------------Pre Loader---------- */
#pre-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  background-color: white;
}

.glow-text-loading {
  animation: flicker 2.5s infinite alternate;
  -webkit-text-fill-color: #0d0d0d;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ED4723;
  font-family: "acid-grotesk";
  font-size: 120px;
  letter-spacing: 5px;
}

.glow-text {
  animation: flicker 2.5s infinite alternate;
  -webkit-text-fill-color: #0d0d0d;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ED4723;
  font-family: "acid-grotesk";
  font-size: 120px;
  letter-spacing: 0.14em;
  position: absolute;
  right: 0;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  margin-right: -50px;
  margin-top: 25%;
}

/* Flickering animation */
@keyframes flicker {

  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 4px transparent, 0 0 11px transparent, 0 0 19px transparent,
      0 0 2px #ED4723, 0 0 4px #ED4723, 0 0 6px #ED4723, 0 0 8px #ED4723,
      0 0 10px #ED4723;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
  }
}


/* -----------Navbar--------------- */
.middlenav {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
  border-left: 1px solid black;
  height: 70px;
}

.logomain {
  width: 70%;
}

.endnav {
  margin-left: auto;
}

.endnav a {
  font-size: 15px !important;
  display: inline;
}

.endnav button {
  display: inline;
  display: inline;
  margin-left: 20px;
  border: none;
  background: none;
}

.engbtn {
  margin-left: 0 !important;
}

.navul {
  margin-bottom: 0;
  padding-left: 0;
}

.navbar .container-fluid, .container-fluid {
  padding-left: 5%;
  padding-right: 5%;
}

#client .item img {
  width: 70%;
}

.navbar-toggler {
  float: right;
}

.nav-underline {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.nav-underline:before, .nav-underline:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: 5px;
  margin-top: -0.5px;
  background: #ED4723;

}

.nav-underline:hover {
  transition: .35s;
  color: #ED4723;
}

.nav-underline:before {
  left: -2.5px;
}

.nav-underline:after {
  right: 2.5px;
  background: #ED4723;
  transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.nav-underline:hover:before {
  background: #ED4723;
  width: 100%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.nav-underline:hover:after {
  background: transparent;
  width: 100%;
  transition: 0s;

}

.nav-link {
  font-size: 15px;
  font-family: "acid-grotesk";
  transition: 0.4s ease all;
  position: relative;
  width: max-content;
}

.navbar .container {
  position: relative;
}

.navbar {
  animation: fadeIn 2s;
  padding-top: 0;
  padding-bottom: 0;
  height: 70px;
  border-bottom: 1px solid black;
}

.navul {
  display: flex;
}

.navul li:nth-child(1) {
  padding-left: 100px !important;
}

.navul li:nth-child(4) {
  padding-right: 100px !important;
}

.navul li {
  padding-left: 30px;
  list-style: none;
}

.nav-link:focus {
  color: #ED4723;
}

.contactbtn {
  background: #ED4723;
  border: none;
  border-radius: 0;
  padding: 13px 20px 8px 20px;
}

.contactbtn:hover {
  background: #ed6344 !important;
  color: white !important;
}

.contac .mobilenav-img {
  display: none;
}

.moblogbrand {
  display: none;
}

/* -------------End Navbar-------------- */

/* ------------Landing section--------------- */
#Landingsection {
  position: relative;
  overflow: hidden;
  background: url("/public/assets/images/homebg.png") no-repeat center;
  background-size: cover;
  height: 90vh;
}

.topcontent {
  position: absolute;
  bottom: 40px;
}

.landing-heading h1 {
  line-height: 1;
  margin-bottom: 0;
  font-size: 55px;
}

.landingrow {
  padding-top: 100px;
  padding-bottom: 100px;
}

.landingcontent-parent, .landingcontent-parentmobile {
  padding-top: 30px;
}

.landingcontent-parentmobile {
  padding-right: 20px;
}

.landingcontent-parent p {
  text-align: justify;
}

.landingcontent-parentmobile {
  display: none;
}

.aboutcontent-parent p {
  text-align: justify;
}

.discovermorebtn {
  background-color: #ED4723;
  border: none;
  border-radius: 0;
  padding: 8px 20px;
  margin-top: 20px;
}

.discovermorebtn:hover {
  background-color: #ed6344 !important;
}

.arrow {
  width: 12px;
  transition: 0.4s ease all;
}

.discovermorebtn:hover .arrow {
  transform: rotate(45deg);
}

.landingcontent-parent p {
  font-family: "acid-grotesk";
  margin-bottom: 0;
}

.reveal {
  visibility: hidden;
  position: relative;
  width: 100%;
  overflow: hidden;
}


.about-img {
  width: 100%;
}

.about-heading {
  font-size: 50px;
}

.about-heading h1 {
  font-size: 55px;
}

/* -----------------PORTFOIO--------------------- */
#portfolio {
  color: white;
  position: relative;
  overflow: hidden;
}

#portfolio a, #allProducts a, #projects a {
  text-decoration: none;
}

#portfolio .container {
  padding-bottom: 100px;
  border-bottom: 1px solid black;
}

.portfolio-heading {
  padding-top: 100px;
}

.portfolio-heading h1 {
  font-size: 55px;
  color: black;
}

figure {
  width: 100%;
  overflow: hidden;
}

.hover01 figure img {
  width: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  filter: grayscale(100%);
}

.hover01 figure:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  filter: grayscale(0);
}

/* -------------Clients------------------- */
#client {
  overflow: hidden;
}

#client .owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}

#client figure {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clients figure img {
  width: 50%;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  filter: grayscale(100%);
}

.clients figure:hover img {
  filter: grayscale(0);
}

.client-heading h1 {
  font-size: 50px;
  padding-top: 100px;
  padding-bottom: 30px;
}

.pspan1 {
  color: black;
  text-transform: uppercase;
}

.pspan2 {
  color: black;
  text-transform: uppercase;
  font-size: 40px;
}

#portfolio button {
  margin: 0 auto;
  display: flex;
  margin-top: 40px;
  border-radius: 0;
  text-decoration: none;
}

/* -------------------About us---------------- */
/* .aboutcontent-parent {
  padding-top: 40px;
} */

.about-usheading, .projects-heading h1, .services-heading h1, .contactus-heading h1 {
  font-size: 55px;
  margin-bottom: 0;
  line-height: 1;
}

.quotebtn {
  background: #ED4723;
  border: none;
  border-radius: 0;
  padding: 8px 20px 8px 20px;
}

.quotebtn:hover {
  background: #ed6344 !important;
  color: white !important;
}

.contactus-heading {
  padding-top: 100px;
  padding-bottom: 100px;
}

.landing-content {
  color: white;
}

.landing-content p {
  text-align: justify;
}

.service-heading {
  position: absolute;
  top: 20px;
  left: 20px;
}

.service-heading h2 {
  font-size: 30px;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 0;
}

.landingrowmobie {
  display: none
}

/* -------------------Team Section--------------- */
#teamsection {
  background: #ED4723;
  margin-top: 100px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

#teamsection img {
  width: 100%;
}

.team-content {
  position: relative;
}

#teamsection h1 {
  margin-bottom: 0;
  font-size: 55px;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
}

.team-title {
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
}

.team-heading {
  padding-bottom: 50px;
}

.team-title p {
  margin-bottom: 0;
}

.pattern {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.pattern-img {
  width: 50%;
}

/* ----------------------Phone section--------------- */
#phonesection, #contact {
  overflow: hidden;
}

#contact {
  z-index: 1;
}

.phone-heading, .phonecontent-parent {
  color: black;
}

.phone-heading h1 {
  font-size: 50px;
  margin-bottom: 0;
  line-height: 1;
}

.phoneinput {
  background-color: #EAEAEA !important;
  border-radius: 0 !important;
  width: 50%;
}

.phonecontent-parent p {
  margin-bottom: 0;
  line-height: 1.4;
}

.phonecontent-parent {
  padding-top: 30px;
  padding-bottom: 20px;
}

.emailform button {
  color: white;
  background-color: #ED4723;
  border: none;
  border-radius: none;
  padding: 8px 20px;
  margin-top: 20px;
}

/* -------------Footer------------------ */
.footer {
  background: #F0F0F0;
  position: relative;
  padding-top: 50px;
}

.founder-heading p {
  text-align: justify;
}

.pattern2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

/* .pattern2 img {
  width: 50%;
} */

.footer h4 {
  font-size: 25px;
  padding-bottom: 15px;
  color: #ED4723;
  padding-left: 15px;
}

.footer-rightcontent ul li a {
  color: #ED4723;
}

.rounded li {
  line-height: 2em !important;
}

.rounded a {
  color: black;
  text-decoration: none;
  font-family: "acid-grotesk";
  transition: 0.4s ease all;
}

.rounded a:hover {
  color: #ED4723;
}

.rounded svg {
  font-size: 25px;
  transition: 0.4s ease all;
}

.socialmedia {
  padding-left: 15px;
}

.socialmedia li {
  list-style: none;
  transition: 0.4s ease all;
}

.socialmedia li:hover svg, .socialmedia li:hover a {
  color: #ED4723;
}

.socialmedia a {
  padding-left: 10px;
}

.lastrow {
  border-top: 2px solid grey;
  padding-top: 15px;
  padding-bottom: 15px;
}

.lastrow a {
  color: #ed6344;
}

.footer-rightcontent {
  justify-content: end;
  display: flex;
}

.footer-rightcontent ul {
  display: inline-flex;
}

.footer-rightcontent ul li {
  list-style: none;
  padding-left: 10px;
}

.mobilenav-img {
  display: none;
}

/* ---------------Abour us Page---------------- */
#about-us, #aboutus, #mission, #imagecarousel, #imagecarousel2, #aboutUS, #aboutsection {
  position: relative;
  overflow: hidden;
}

.mission-heading h1, .vission-heading h1 {
  color: black;
}

#about-us {
  background: url("/public/assets/images/aboutbg.png") no-repeat center;
  background-size: cover;
  height: 90vh;
}

#products {
  background: url("/public/assets/images/productbg.png") no-repeat center;
  background-size: cover;
  height: 90vh;
}

#services {
  background: url("/public/assets/images/servicesbg.png") no-repeat center;
  background-size: cover;
  height: 90vh;
  overflow: hidden;
  position: relative;
}

.doit-heading h1, .doit-heading p {
  color: black;
}

.service-content-main {
  position: absolute;
  bottom: 40px;
  color: white;
}

.service-content-main p {
  padding-top: 30px;
}

#projects {
  position: relative;
  overflow: hidden;
  background: url("/public/assets/images/projects.png") no-repeat center;
  background-size: cover;
  height: 90vh;
  color: white;
}
#projectdetail{
  position: relative;
  overflow: hidden;
  background: url("/public/assets/images/Projectdetail.png") no-repeat center;
  background-size: cover;
  height: 90vh;
  color: white;
}

.projects-heading p {
  padding-top: 30px;
}

.aboutlandingrow {
  color: white;
  position: absolute;
  bottom: 40px;
}

/* .aboutus-heading{
  padding-top: 100px;
} */
.aboutus-heading h1 {
  font-size: 40px;
  margin-bottom: 0;
  line-height: 1;
  color: black;
}

.aboutuscontent-parent {
  padding-top: 50px;
  text-align: justify;
  color: black;
}

.patternaboutus {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  z-index: -1;
}

.cardsimg {
  position: relative;
}

.workimg-heading {
  position: absolute;
  top: 15px;
  left: 20px;
  right: 15px;
}

.cardsimgpic {
  width: 100%;
}

.overlay-cardsimg h2 {
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1;
  margin-bottom: 0;
}

.overlay-cardsimg {
  padding: 20px 20px 20px 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: #8D8D8D;
  border-radius: 1rem;
  transition: 0.4s ease all;
}

.product-mobile, .aboutus-mobile {
  display: none;
}
.sharebtns{
  display: inline-block;
}
.sharebtns li, .inline{
  display: inline;       
}
.inline{
  text-decoration: underline;
  font-weight: bold;
}
.sharebtns svg{
  background: #ED4723;
    color: white;
    font-size: 25px;
    border-radius: 50%;
    padding: 3px;
    transition: 0.4s ease all;
}
.sharebtns svg:hover{
  background: #e75737;
  color: white !important;
}
.cardsimg-para {
  position: absolute;
  bottom: 0;
}

.cardsimg-para p {
  font-size: 12px;
  padding-left: 45%;
}

.cardsimg:hover .overlay-cardsimg {
  color: white;
  opacity: 1;
}

.cards-para {
  position: absolute;
  bottom: 0px;
}

.zigzag {
  display: flex;
  justify-content: center;
  position: relative;
}

.zigzag img {
  width: 85%;
  margin-top: 10%;
}

.card img {
  width: 25%;
}

.services-parent {
  display: flex;
  align-items: center;
  width: 75%;
  position: absolute;
}

.services-parent1 {
  top: 0;
}

.services-parent2 {
  top: 18%;
  right: -40px;
}

.services-parent3 {
  top: 36%;
}

.services-parent4 {
  top: 56%;
  right: -40px;
}

.services-parent5 {
  top: 74%;
}

.services-parent6 {
  bottom: 2%;
  right: -40px;
}

.service-img {
  width: 100%;
}

.service-img img {
  width: 100%;
}

.service-content {
  padding-left: 30px;
  padding-right: 50px;
}

.patternservice {
  width: 40% !important;
}

.patternservice img {
  width: 100%;
}

/* ------------Products-------------- */
#products, #allProducts {
  position: relative;
  overflow: hidden;
}

.product-figure {
  position: relative;
}

.product-figure img {
  width: 100%;
}

.left-content h2 {
  font-size: 30px;
}

.left-content p {
  color: #808080ba;
}

.product-figure img.Sirv.image-hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: contain;
  opacity: 0;
  transition: opacity .3s;
  width: 100%;
}

.product-figure:hover img.Sirv.image-hover {
  opacity: 1;
}

.left-content {
  padding-top: 20px;
}

.left-content h2 {
  margin-bottom: 0;
}

#allProducts button {
  display: flex;
  margin: 0 auto;
  margin-top: 30px;
}

.product-heading {
  position: absolute;
  bottom: 40px;
}

.product-heading p {
  padding-top: 30px;
}

.product-heading h1 {
  margin-bottom: 0;
  line-height: 1;
  font-size: 55px;
}

.ourproduct-heading {
  padding-bottom: 40px;
  font-size: 40px;
  padding-top: 100px;
}

.homesection8 {
  overflow: hidden;
}

.product-contentparent p {
  padding-right: 40%;
  text-align: justify;
}

.product-content {
  margin-top: 10px;
}

.product-content h2 {
  font-size: 22px;
  padding-top: 10px;
  color: black !important;
}

.product-content img {
  width: 12% !important;
  margin-top: 5px;
}

/* ----------------------Projects--------------------- */
.nav-tabs {
  border: none;
}
#productdetail .owl-theme .owl-nav{
  position: absolute;
  top: -20px;
  right: 50px;
}
.active {
  color: #ED4723 !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #ED4723 !important;
  border-bottom: 1px solid #ED4723;
  border-top: none;
  border-left: none;
  border-right: none;
}

.projects-headingmobile, .services-mobile, .contactus-headingmobile {
  display: none;
}

.projects-heading {
  position: absolute;
  bottom: 40px;
  color: white;
}

.nav-tabs .nav-link {
  color: black;
}

#projects-tab a{
  text-decoration: none;
}
.project-content h2, .product-content h2 {
  display: inline;
}

.servicebottom-content, .doit-heading {
  padding-top: 50px;
}

.date, .product-content img {
  float: right;
}

.landingmobile-heading {
  display: none;
}

.tab-content button {
  margin: 0 auto;
  text-align: center;
  display: flex;
  margin-top: 100px;
  margin-bottom: 80px;
}

/* -----------------------Contact us-------------------- */
.contactform {
  width: 100% !important;
  background-color: transparent !important;
  border: 1px solid black;
  margin-bottom: 20px;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #ED4723;
}

.contact-content p {
  text-align: justify;
}

/* ---------Product Details------------------ */
#projectdetail .owl-prev:hover, #productdetail .owl-prev, #productdetail .owl-next, #projectdetail .owl-next{
  background: transparent !important;
}
#productdetail .owl-prev{
  position: absolute;
  bottom: 0%;
  cursor: pointer;
  left: -100px;
  transition: 0.4s ease all;
  display: flex;
  align-items: center;
  justify-content: center;
}
#productdetail .owl-carousel .owl-nav.disabled{
  display: block !important;
}
#projectdetail .owl-prev{
  position: absolute;
  bottom: -9%;
  cursor: pointer;
  right: 100px;
  transition: 0.4s ease all;
  display: flex;
  align-items: center;
  justify-content: center;
}
#productdetail .owl-prev:hover, #productdetail .owl-next:hover,#projectdetail .owl-prev:hover, #projectdetail .owl-next:hover {
  color: #ED4723 !important;
}

#projectdetail .owl-next {
  position: absolute;
  bottom: 0;
  cursor: pointer;
  right: 0;
  transition: 0.4s ease all;
  display: flex;
  align-items: center;
  justify-content: center;
}
#productdetail .owl-next {
  position: absolute;
  bottom: 0;
  cursor: pointer;
  right: -40px;
  transition: 0.4s ease all;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-group button{
  padding-left: 0;
}
.btn-group button>img{
  width: 18px;
}
.arrowprev {
  width: 8px !important;
  margin-right: 5px !important;
}

.arrownext {
  width: 8px !important;
  margin-left: 5px !important;
  transform: rotate(180deg) !important;
}

#productdetail .owl-next span, #productdetail .owl-prev span {
  font-size: 50px !important;
  color: white !important;
}

#productdetail .owl-theme .owl-nav [class*='owl-']:hover {
  background: transparent !important;
}
.projectdetail1img{
  width: 100%;
}
.proectcarousel .owl-theme .owl-nav{
  text-align: right;
}
.proectcarousel .owl-carousel .owl-nav button.owl-next{
  margin-left: 50px;
  transition: 0.4s ease all;
}
.proectcarousel .owl-carousel .owl-nav button.owl-prev{
  transition: 0.4s ease all;
}
.proectcarousel .owl-carousel .owl-nav button.owl-next:hover,.proectcarousel .owl-carousel .owl-nav button.owl-prev:hover{
  background: transparent !important;
  color: #ED4723 !important;
}
.mobile-view{
 display: none;
}
#productdetail .owl-carousel.owl-drag .owl-item a{
  text-decoration: none !important;
}

/* .flex_row{
  justify-content: center;
  display: flex;
  padding-top: 20px;
} */

.thumbnail img{
width: 100%;
height: 60px;
object-fit: cover;
margin-top: 20px;
}
.clicked{
border: 2px solid rgb(91, 91, 91) !important;
}
.proectcarousel a{
  text-decoration: none;
}
.product-material-mobile{
  display: none;
}
.product-material{
  display: block;
}
.sliderparent{
  overflow: hidden;
}
.sliderparentimg{
  height: 400px;
  border-radius: 0.5rem;
}
.carosel {
  position: relative;
  margin-top: 20px;
  display: flex;
  width: 100%;
  height: 70px;
  overflow-x: scroll;
  overflow-y: hidden;
  transition: 0.4s ease all;
  scroll-behavior: smooth;
}
.slide{
  width: 70px;
  height: 60px;
  margin-right: 10px;
  border-radius: 0.2rem;
  transition: 0.4s ease all;
}
.arrow{
  position: sticky;
  width: 1.2rem;
  height: 1.2rem;
  color: #e75737;
  transition: 0.4s ease all;
}
.arrow:hover{
  cursor: pointer;
  color: black;
}
/* .arrow-left{
  left: 1rem;
} */
.arrow-right{
  margin-left: 30px;
}
.arrow-btns{
  text-align: center;
}
.carosel::-webkit-scrollbar {
  display: none;
}